import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"
import Section from "../components/section"
import TwmButton from "../components/twm-button"

const TrueWealthGroupPage = props => (
  <Layout firmName={props.pageContext.firm} firmWeb={props.pageContext.web}>
    <SEO title="True Wealth Group" />
    <BackgroundImage
      fluid={props.data.pileOfStonesImage.childImageSharp.fluid}
      alt="A carefully balanced pile of stones"
    >
      <Header headerText="True Wealth Group"></Header>
    </BackgroundImage>
    <Section>
      <h2>True Wealth Group</h2>

      <p>
        TAG Financial Planning is part of the True Wealth group of companies
        which comprises a number of financial advice practises as well as firms
        that provide services to financial advisers. This includes{" "}
        <a href="https://navigatorfp.com">
          Navigator Financial Planning Limited
        </a>{" "}
        of which TAG Financial Planning (and a number of other firms e.g.{" "}
        <a href="https://truewm.com">True Wealth Management</a>) is an appointed
        representative.
      </p>
      <p>
        TAG Financial Planning and its management team also has close working
        relationships with other financial practises such as{" "}
        <a href="https://lawsoc-nifa.org">Law Society (NI) Financial Advice</a>{" "}
        with which it shares a common executive directorship.
      </p>
      <p>
        Through its ownership of Navigator Financial Planning and{" "}
        <a href="http://leestrathy.com">Lee Strathy Limited</a> True Wealth
        group also has an association with the{" "}
        <a href="https://ebisgroup.org">EBIS group</a>.
      </p>

      <p>
        True Wealth is founded and developed by financial planners for financial
        planners and their clients with a shared objective of creating ‘
        <strong>true wealth</strong>’.
      </p>

      <h2>Truly Independent</h2>
      <p>
        The True Wealth Group is funded by its founders and private investors
        and is therefore in control of its own destiny. It has no corporate or
        institutional investors with vested interests to influence how we craft
        our client propositions. We think this reduces the potential for
        conflict of interest and helps ensure that our advisers remains truly
        impartial and independent.
      </p>

      <TwmButton toRef={props.pageContext.firm + "/contact-us"}>
        Contact Us
      </TwmButton>
    </Section>
  </Layout>
)

export default TrueWealthGroupPage

export const pageQuery = graphql`
  query {
    pileOfStonesImage: file(relativePath: { eq: "pile-of-stones.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
